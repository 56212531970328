import classNames from 'classnames';
import React, { FC, RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { Builder } from '@builder.io/react';
import omit from 'lodash/omit';
import { TopbarContainer } from '..';
import {
  BuilderSection,
  Button,
  ConditionalWrapper,
  Footer,
  IconArrowDown,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  SectionFooterActionV2,
  SubscribeForm,
  TypographyWrapper,
} from '../../components';
import SectionHeroV2 from '../../components/SectionHeroV2/SectionHeroV2';
import { useShopConfig } from '../../hooks/shopConfig';
import { ModalParams, parse } from '../../util/urlHelpers';
import { AboutPageTab } from '../AboutPage/aboutPageUtils';
import {
  areSearchParamsInSync,
  pickSearchParamsOnly,
  validFilterParams,
} from './LandingPageV2.helpers';
import { RequestStatus } from '../../types/requestStatus';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Feature } from '../../util/featureFlags';
import { sortConfigForFrenzy } from '../../util/frenzyHelpers';
import config, { LANDING_PAGE_SECTION } from '../../shopConfig/config';
import { ButtonVariant, InlineTextButton } from '../../components/Button/Button';
import { useIsMobile } from '../../hooks/useIsMobile';
import {
  fetchAndInsertSoldListings,
  LandingPageV2State,
  searchListings,
  updateNotifications,
} from './LandingPageV2.duck';
import SearchNavbar from './SearchNavbar';
import SearchResults from './SearchResults';
import MultiButtonWrapper from '../../components/MultiButtonWrapper/MultiButtonWrapper';
import { UpdateGeneralSavedSearchEmailSubscriptionParams } from '../../types/models/savedSearch';
import windowScroll from '../../util/scrollHelpers';
import {
  Cadence,
  SavedSearchType,
  useSavedSearchByEmailLazyQuery,
} from '../../types/apollo/generated/types.generated';
import { CurrentUser } from '../../types/sharetribe/currentUser';
import AppContext from '../../context/AppContext';
import { setStorageKey, SubscribeModalState } from '../../ducks/subscribeModal.duck';
import { getLocalStorage } from '../../util/localStorageHelpers';
import { useUserCountryConfig } from '../../hooks/useCountryConfig';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { useWindowScroll } from '../../hooks/useWindowScroll';
import { BuilderSections } from '../../util/builder';
import { BuilderLandingPageContentResponseData } from '../../types/builder/builder';
import TopbarTransparencyContext from '../../context/TopbarTransparencyContext';
import { ModalState, ModalType, setActiveModal } from '../../ducks/modal.duck';
import css from './LandingPageV2.module.css';

const LANDING_PAGE_MODAL_TRIGGERED_STORAGE_KEY = 'landing_page_modal_triggered';
const TWENTY_SECONDS = 20000; // in ms
const DEFAULT_TRANSPARENT_TOPBAR_SCROLL_THRESHOLD = 50; // in pixels

const smoothScrollToSearchNav = (
  searchNavbarRef?: RefObject<HTMLDivElement>,
  navbarRef?: RefObject<HTMLDivElement>
) => {
  const calculateScrollHeight = () => {
    // Handles autoscrolling better when called from useEffect on render
    if (searchNavbarRef && navbarRef) {
      return (searchNavbarRef?.current?.offsetTop || 0) - (navbarRef?.current?.offsetHeight || 0);
    }

    // If no refs are present (i.e. from a builder component):
    const searchNav =
      (typeof document !== 'undefined' && document.getElementById('search')?.offsetTop) || 0;
    const navbar =
      (typeof document !== 'undefined' && document.getElementById('topbar')?.offsetHeight) || 0;

    return searchNav - navbar;
  };

  windowScroll({
    top: calculateScrollHeight(),
    behavior: 'smooth',
  });
};

const LearnMoreButton: FC = () => {
  const {
    copy: { learnMoreButtonText },
  } = useShopConfig();
  return (
    <NamedLink
      name="AboutPage"
      params={{ tab: AboutPageTab.Info }}
      style={{ textDecoration: 'none' }}
    >
      <Button className={css.button} variant={ButtonVariant.Secondary} style={{ width: '100%' }}>
        {learnMoreButtonText}
      </Button>
    </NamedLink>
  );
};

const ShopAndLearnMoreButtonsDesktop: FC<{ handleShopClick?: () => void }> = (props) => {
  const { handleShopClick } = props;
  const {
    copy: { shopButtonText },
  } = useShopConfig();

  let hasSearchNav = false;
  if (typeof document !== 'undefined') {
    hasSearchNav = !!document.getElementById('search');
  }

  const onShopButtonClick = () => {
    if (handleShopClick) return handleShopClick();
    if (hasSearchNav) return smoothScrollToSearchNav();
    return null;
  };

  return (
    <MultiButtonWrapper>
      <React.Fragment key="shop-button">
        <ConditionalWrapper
          condition={!hasSearchNav}
          wrapper={(children) => (
            <NamedLink name="LandingPage" to={{ search: 'mode=raw-query' }}>
              {children}
            </NamedLink>
          )}
        >
          <Button
            key="shop-button"
            style={{ width: '100%' }}
            className={css.button}
            variant={ButtonVariant.Secondary}
            onClick={onShopButtonClick}
          >
            {shopButtonText}
          </Button>
        </ConditionalWrapper>
      </React.Fragment>
      <React.Fragment key="learn-more-button">
        <LearnMoreButton />
      </React.Fragment>
    </MultiButtonWrapper>
  );
};

const ShopButtonMobile: FC<{ handleShopClick?: () => void }> = (props) => {
  const { handleShopClick } = props;

  const {
    copy: { shopButtonText },
  } = useShopConfig();

  let hasSearchNav = false;
  if (typeof document !== 'undefined') {
    hasSearchNav = !!document.getElementById('search');
  }

  const onShopButtonClick = () => {
    if (handleShopClick) return handleShopClick();
    if (hasSearchNav) return smoothScrollToSearchNav();
    return null;
  };

  return (
    <ConditionalWrapper
      condition={!hasSearchNav}
      wrapper={(children) => (
        <NamedLink name="LandingPage" to={{ search: 'mode=raw-query' }}>
          {children}
        </NamedLink>
      )}
    >
      <InlineTextButton
        style={{ textDecoration: 'none' }}
        type="button"
        onClick={onShopButtonClick}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <TypographyWrapper
            component="span"
            variant="h2"
            typographyOverrides={{ style: { color: 'white', display: 'block' } }}
          >
            {shopButtonText}
          </TypographyWrapper>
          <IconArrowDown color="white" />
        </Box>
      </InlineTextButton>
    </ConditionalWrapper>
  );
};

interface ActionHeroProps {
  navbarRef: RefObject<HTMLDivElement>;
  searchNavbarRef: RefObject<HTMLDivElement>;
}

const ActionHero: FC<ActionHeroProps> = (props) => {
  const { navbarRef, searchNavbarRef } = props;

  const isMobile = useIsMobile();

  const handleShopClick = () => smoothScrollToSearchNav(searchNavbarRef, navbarRef);

  if (isMobile) {
    return (
      <SectionHeroV2
        actionEl={<LearnMoreButton />}
        bottomActionEl={<ShopButtonMobile handleShopClick={handleShopClick} />}
        shouldAnimate
      />
    );
  }

  return (
    <SectionHeroV2
      actionEl={<ShopAndLearnMoreButtonsDesktop handleShopClick={handleShopClick} />}
      shouldAnimate
    />
  );
};

const ActionFooter: FC<{
  footerRef: RefObject<HTMLHeadingElement>;
}> = (props) => {
  const { footerRef } = props;

  const {
    landingPageLayoutOrder,
    copy: { subscribeTitle, subscribeSubtitle },
    marketingSettingsConfig: { hideSizesFromSubscribe = false } = {},
  } = useShopConfig();

  const dispatch = useDispatch();
  const { updateNotificationsStatus } = useSelector<any>(
    (state) => state.LandingPageV2
  ) as LandingPageV2State;

  if (!landingPageLayoutOrder.includes(LANDING_PAGE_SECTION.FOOTER)) return null;
  const descriptionCopy =
    subscribeSubtitle ||
    'Get updates for new releases, discounts, and personalized “just for you” collections from Treet.';
  const headerCopy = subscribeTitle || 'Nothing catching your eye?';

  const onSubscribe = (params: UpdateGeneralSavedSearchEmailSubscriptionParams) =>
    dispatch(updateNotifications(params));

  const headerEl = (
    <div ref={footerRef}>
      <TypographyWrapper
        variant="h1"
        typographyOverrides={{ style: { color: 'white', textAlign: 'center' } }}
      >
        {headerCopy}
      </TypographyWrapper>
    </div>
  );

  let footerActionEl;
  if (updateNotificationsStatus === RequestStatus.Success) {
    footerActionEl = (
      <Typography variant="h2" style={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
        Thank you, your email is subscribed!
      </Typography>
    );
  } else {
    footerActionEl = (
      <Box pt={2}>
        <SubscribeForm onSubscribe={onSubscribe} areSizesEnabled={!hideSizesFromSubscribe} />
      </Box>
    );
  }

  return (
    <SectionFooterActionV2
      headerEl={headerEl}
      description={descriptionCopy}
      actionEl={footerActionEl}
    />
  );
};

const LandingPageV2: FC = () => {
  const { treetId } = useContext(AppContext);

  const [builderData, setBuilderData] = useState<
    BuilderLandingPageContentResponseData | undefined
  >();
  const [shouldMaybeOpenSubscribeModal, setShouldMaybeOpenSubscribeModal] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [topbarHeight, setTopbarHeight] = useState<number | null>(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useIsMobile();
  const scroll = useWindowScroll();
  const {
    treetShopName,
    images,
    filters: shopConfigFilters,
    shopName,
    builderConfig,
    landingPageTransparentTopbarScrollThreshold,
    copy,
    css: brandCss,
  } = useShopConfig();
  const {
    canShipFromUserCountry: canUserListInCountry,
    canShipToUserCountry,
    canShipWithinUserCountry,
  } = useUserCountryConfig();
  const { allowBuy, allowList } = useEnabledCustomerExperiences();

  const isFrenzySearchEnabled = useFeatureFlags(Feature.FrenzySearch);
  const isLandingPageSubscribeModalEnabled = useFeatureFlags(Feature.LandingPageSubscribeModal);

  const currentUser = useSelector<any>((state) => state.user.currentUser) as
    | CurrentUser
    | undefined;
  const { searchApiFilters, pagination, searchParams, searchListingsStatus, soldListingsStatus } =
    useSelector<any>((state) => state.LandingPageV2) as LandingPageV2State;
  const { activeModal } = useSelector<any>((state) => state.modal) as ModalState;
  const { authInfoLoaded, isAuthenticated } = useSelector<any>((state) => state.Auth) as any;
  const { storageKey } = useSelector<any>((state) => state.subscribeModal) as SubscribeModalState;

  useEffect(() => {
    const queryParams = parse(location.search);
    const searchListingsParams = omit(queryParams, Object.values(ModalParams));
    dispatch(searchListings(searchListingsParams));
  }, [location.search]);

  useEffect(() => {
    if (
      searchListingsStatus === RequestStatus.Success &&
      soldListingsStatus === RequestStatus.Ready
    ) {
      dispatch(fetchAndInsertSoldListings());
    }
  }, [searchListingsStatus]);

  const [
    querySavedSearchByEmail,
    { loading: isQuerySavedSearchesLoading, data: querySavedSearchesResponse },
  ] = useSavedSearchByEmailLazyQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (currentUser) {
      const { email } = currentUser.attributes;
      querySavedSearchByEmail({ variables: { email, treetId, type: SavedSearchType.General } });
    }
  }, [currentUser]);

  const canUserBuyInCountry = canShipToUserCountry || canShipWithinUserCountry;
  const shouldShowCountryWarningModal =
    (allowBuy && !canUserBuyInCountry) || (allowList && !canUserListInCountry);

  useEffect(() => {
    // If we display the country warning modal, return early so that we don't overload the user
    // with modals
    if (shouldShowCountryWarningModal) return;

    if (!isLandingPageSubscribeModalEnabled) return;
    if (!authInfoLoaded || isQuerySavedSearchesLoading) return;

    if (isAuthenticated) {
      // User is logged in but we haven't finished fetching their saved searches
      if (!querySavedSearchesResponse) return;

      const savedSearchCadence = querySavedSearchesResponse.savedSearch?.cadence;
      if (savedSearchCadence && savedSearchCadence !== Cadence.Never) return;
    }

    const localStorageValue = getLocalStorage(LANDING_PAGE_MODAL_TRIGGERED_STORAGE_KEY) || '';
    if (localStorageValue.includes(treetId)) return;

    // User might view landing page multiple times before the modal appears; we only want it
    // to show the first time so we shouldn't take action any subsequent time
    const hasTriggeredLandingPageModal = storageKey === LANDING_PAGE_MODAL_TRIGGERED_STORAGE_KEY;
    if (hasTriggeredLandingPageModal) return;

    if (activeModal == null) {
      dispatch(setStorageKey(LANDING_PAGE_MODAL_TRIGGERED_STORAGE_KEY));
      setTimeout(() => setShouldMaybeOpenSubscribeModal(true), TWENTY_SECONDS);
    }
  }, [authInfoLoaded, isQuerySavedSearchesLoading, isAuthenticated, querySavedSearchesResponse]);

  useEffect(() => {
    if (shouldMaybeOpenSubscribeModal) {
      if (activeModal == null) {
        dispatch(setActiveModal(ModalType.Subscribe));
      }
      setShouldMaybeOpenSubscribeModal(false);
    }
  }, [shouldMaybeOpenSubscribeModal]);

  // Begin Auto-Scroll behavior
  const actionFooterRef = useRef<HTMLHeadingElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const searchNavbarRef = useRef<HTMLDivElement>(null);

  const locationState = location.state as { scrollOnRender?: boolean } | undefined;

  // calculate topbar height to know how much to offset css top property of searchNavBar
  /* eslint-disable consistent-return */
  useEffect(() => {
    const navbar = navbarRef.current;
    if (navbar) {
      const resizeObserver = new ResizeObserver((entries) => {
        /* eslint-disable no-restricted-syntax */
        for (const entry of entries) {
          const target = entry.target as HTMLElement;
          setTopbarHeight(target.offsetHeight);
        }
        /* eslint-enable no-restricted-syntax */
      });

      resizeObserver.observe(navbar);

      return () => {
        resizeObserver.unobserve(navbar);
      };
    }
  }, [navbarRef]);
  /* eslint-enable consistent-return */

  useEffect(() => {
    if (locationState?.scrollOnRender) {
      smoothScrollToSearchNav(searchNavbarRef, navbarRef);
    }
  }, [locationState]);
  // End Auto-Scroll behavior

  const sortConfig = isFrenzySearchEnabled ? sortConfigForFrenzy : config.custom.treetV2SortConfig;
  const filters = searchApiFilters || shopConfigFilters;

  const { ...searchInURL } = parse(location.search);
  const urlQueryParams = pickSearchParamsOnly(searchInURL, filters, sortConfig);

  const searchParamsAreInSync = areSearchParamsInSync(
    urlQueryParams,
    searchParams,
    filters,
    sortConfig
  );
  const hasSearchParamsPresent = searchInURL?.mode || searchInURL?.page;
  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const isSearchInProgress = searchListingsStatus === RequestStatus.Pending;
  const hasFetchedSoldListings =
    soldListingsStatus === RequestStatus.Success || soldListingsStatus === RequestStatus.Error;
  const areListingsLoaded =
    !isSearchInProgress && searchParamsAreInSync && hasPaginationInfo && hasFetchedSoldListings;
  const shouldShowHero = !hasSearchParamsPresent;
  const landingPageBuilderSectionId = builderConfig?.sections?.[BuilderSections.LandingPageContent];

  const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination!.totalItems : 0;
  const page = searchParamsAreInSync && hasPaginationInfo ? pagination!.page : 1;
  const pageNumberTitleText = page > 1 ? `Page ${page} | ` : '';
  const pageNumberDescriptionText = page > 1 ? ` Search results page ${page}.` : '';

  const selectedFilters = validFilterParams(urlQueryParams, filters);
  const selectedFiltersCount = Object.keys(selectedFilters).length;

  const toggleIsMobileModalOpen = () => setIsMobileModalOpen(!isMobileModalOpen);

  const schemaTitle = copy?.seoSiteTitle || treetShopName;
  const schemaDescription =
    copy?.seoSiteDescription || copy?.heroSubtitle || `Buy and sell pre-loved ${shopName} items.`;
  const heroImageDefaultUrl = images?.heroImage?.url;
  const heroImageMobileUrl = images?.heroImageMobile?.url;
  const heroImageUrl = (isMobile && heroImageMobileUrl) || heroImageDefaultUrl;

  // Hide these sections on load if brand has a builder.io custom page.
  const shouldShowFooter = !landingPageBuilderSectionId || builderData?.showFooter;
  const shouldShowSearchResults =
    !landingPageBuilderSectionId ||
    builderData?.showUnfilteredSearchResults ||
    hasSearchParamsPresent;

  // Topbar styles
  const isFullBleedTopbar = shouldShowHero && (brandCss?.bleedTopbarIntoHeroImage ?? false);
  const transparentTopbarScrollThreshold =
    landingPageTransparentTopbarScrollThreshold ?? DEFAULT_TRANSPARENT_TOPBAR_SCROLL_THRESHOLD;

  const isTopbarTransparent = isFullBleedTopbar && scroll.y < transparentTopbarScrollThreshold;

  // Set topbar class based on if a modal is open in
  // a child component
  const topbarClasses = classNames(css.topbar, {
    [css.topbarBehindModal]: isMobileModalOpen,
    [css.topbarAboveHero]: isFullBleedTopbar,
  });

  return (
    <Page
      contentType="website"
      description={`${schemaDescription}${pageNumberDescriptionText}`}
      title={`${pageNumberTitleText}${treetShopName}`}
      // TODO (SY|TREET-1008): Change sharing images to be a different image
      facebookImages={[{ url: heroImageUrl, width: 1200, height: 630 }]}
      twitterImages={[{ url: heroImageUrl, width: 600, height: 314 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: heroImageUrl,
      }}
      // Hide the page from search engines if we're on a paginated search results page
      shouldNotIndex={page > 1}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar className={topbarClasses} elRef={navbarRef}>
          <TopbarTransparencyContext.Provider value={isTopbarTransparent}>
            <TopbarContainer />
          </TopbarTransparencyContext.Provider>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {!!landingPageBuilderSectionId && shouldShowHero && (
            <BuilderSection
              sectionType={BuilderSections.LandingPageContent}
              sectionId={landingPageBuilderSectionId}
              fetchDataFromBuilder={(builderResponse) => setBuilderData(builderResponse)}
            />
          )}
          {!landingPageBuilderSectionId && shouldShowHero && (
            <ActionHero navbarRef={navbarRef} searchNavbarRef={searchNavbarRef} />
          )}
          {shouldShowSearchResults && (
            <>
              <SearchNavbar
                toggleIsMobileModalOpen={toggleIsMobileModalOpen}
                urlQueryParams={urlQueryParams}
                sortConfig={sortConfig}
                filters={filters}
                totalItems={totalItems}
                elRef={searchNavbarRef}
                topbarHeight={topbarHeight}
              />
              <SearchResults
                listingsAreLoaded={areListingsLoaded}
                totalItems={totalItems}
                areFiltersApplied={selectedFiltersCount > 0}
                subscribeBannerActionScrollToRef={actionFooterRef}
              />
            </>
          )}
          {/* To prevent content shift, make sure the search results are ready to render before
          displaying anything below it. */}
          {areListingsLoaded && shouldShowFooter && <ActionFooter footerRef={actionFooterRef} />}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>{areListingsLoaded && <Footer />}</LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

Builder.registerComponent(ShopAndLearnMoreButtonsDesktop, {
  name: 'ShopAndLearnMoreButtonsDesktop',
});

// A simplified version of these two buttons, for Builder.
const ShopAndLearnMoreButtonsMobile = () => (
  <Box>
    <LearnMoreButton />
    <Box mt={3} textAlign="center">
      <ShopButtonMobile />
    </Box>
  </Box>
);

Builder.registerComponent(ShopAndLearnMoreButtonsMobile, {
  name: 'ShopAndLearnMoreButtonsMobile',
});

export default LandingPageV2;
